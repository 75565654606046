<template>
  <div class="classSubject" @click="toDetailSubject">
    <a-card
      class="cardSubject"
      :headStyle="{
        background: `linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('/resources/images/covers/${subject.nama}/cover.png')`,
        backgroundSize: '100% 100%',
      }"
      :title="subject.nama"
    >
      <a-row>
        <a-col :span="12">
          <p style="color: #707788">Next Session</p>
          <template v-if="nextSession !== '-'">
            <h3>{{ nextSession.day }}</h3>
            <h3>{{ nextSession.time }}</h3>
          </template>
          <template v-else>
            <h3>-</h3>
          </template>
        </a-col>
        <a-col :span="12">
          <p style="color: #707788">Assignment</p>
          <template v-if="tugasDeadlineLastSession">
            <h3>
              {{ assignmentDone }}
              <span>Submitted/{{ totalTaskLastSession }}</span>
            </h3>
            <p style="font-size: 14px; color: red">
              <a-icon type="hourglass" />
              {{ tugasDeadlineLastSession }}
            </p>
          </template>
          <template v-else>
            <h3>-</h3>
          </template>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  props: ['subject'],
  methods: {
    toDetailSubject() {
      this.$store.commit('subject/SET_STATE', {
        currentSubject: this.subject,
      })
      this.$router.push({ name: 'Detail Subject Student', params: { idSubject: this.subject.id } })
    },
    assignmentCount() {
    },
  },
  data() {
    return {
      tugasDeadlineLastSession: '',
      totalTaskLastSession: null,
      assignmentDone: null,
    }
  },
  computed: {
    nextSession() {
      const now = moment()
      let nextSession = '-'
      this.subject.slots.forEach((slot, idxSlot) => {
        const { jam_mulai: jamMulai } = slot.model_jadwal
        slot.sesis.forEach((sesi, idxSesi) => {
          const fullDateAndTime = moment(`${sesi.tanggal_sesi} ${jamMulai}`, 'YYYY-MM-DD HH:mm').locale('id')
          if (idxSlot === 0 && idxSesi === 0) {
            nextSession = fullDateAndTime
          } else {
            if (moment(nextSession, 'DD MMMM YYYY HH:mm').diff(now, 'seconds') < 0) {
              nextSession = fullDateAndTime
            } else if (fullDateAndTime > now && fullDateAndTime.diff(now, 'seconds') > 0 && fullDateAndTime.diff(now, 'seconds') < moment(nextSession, 'DD MMMM YYYY HH:mm').diff(now, 'seconds')) {
              nextSession = fullDateAndTime
            }
          }
        })
      })

      if (nextSession === '-') {
        return nextSession
      } else if (nextSession < now) {
        return '-'
      } else {
        return {
          day: moment(nextSession).locale('id').format('DD MMM YYYY'),
          time: moment(nextSession).format('HH:mm'),
        }
      }
    },
  },
  created() {
    // FIND ASSIGNMENTS
    const now = moment()
    let lastSession = '-'
    let idxLastSession = {
      slot: null,
      sesi: null,
    }
    this.subject.slots.forEach((slot, idxSlot) => {
      const { jam_mulai: jamMulai } = slot.model_jadwal
      slot.sesis.forEach((sesi, idxSesi) => {
        const fullDateAndTime = moment(`${sesi.tanggal_sesi} ${jamMulai}`, 'YYYY-MM-DD HH:mm').locale('id')
        if (idxSlot === 0 && idxSesi === 0) {
          lastSession = fullDateAndTime
          idxLastSession = {
            slot: idxSlot,
            sesi: idxSesi,
          }
        } else {
          if (moment(lastSession, 'DD MMMM YYYY HH:mm').diff(now, 'seconds') > 0) {
            lastSession = fullDateAndTime
          }
          if (fullDateAndTime < now && fullDateAndTime.diff(now, 'seconds') < 0 && fullDateAndTime.diff(now, 'seconds') > moment(lastSession, 'DD MMMM YYYY HH:mm').diff(now, 'seconds')) {
            lastSession = fullDateAndTime
            idxLastSession = {
              slot: idxSlot,
              sesi: idxSesi,
            }
          }
        }
      })
    })

    if (lastSession !== '-' && lastSession < now) {
      const { slot, sesi } = idxLastSession
      const tasks = this.subject.slots[slot].sesis[sesi]
      // console.log(slot, sesi, `ini pelajaran ${this.subject.nama}`, tasks.tugas_deadline2)
      let taskDone = 0
      this.tugasDeadlineLastSession = tasks.tugas_deadline2 ? moment(tasks.tugas_deadline2).locale('id').format('DD MMMM YYYY') : ''
      this.totalTaskLastSession = tasks.tugas_deadline2 == null ? 0 : 1
      tasks.attendances.forEach(attendance => {
        if (attendance.submit_time) {
          taskDone += 1
        }
      })
      this.assignmentDone = taskDone
    }
  },
}
</script>
<style lang="scss">
.classSubject {
  .cardSubject {
    transition: all 0.3s;
    cursor: pointer;
  }

  .cardSubject:hover {
    transform: translate(0, -5px);
    box-shadow: 0px 0px 20px #0419301a;
    transition: all 0.3s;
  }

  p,
  h3 {
    margin-bottom: 5px !important;
  }

  h3 > span {
    font-family: "Mukta", "sans-serif";
    font-weight: 400;
    color: #707788;
    font-size: 16px;
  }
  .ant-card {
    border: none;
    box-shadow: 0px 0px 10px #0419301a;
    border-radius: 6px;
    min-height: 260px;
  }

  .ant-card-head {
    min-height: 125px;
    display: flex;
    align-items: center;
    color: white;
    opacity: 1;
    border-radius: 6px;
  }

  .ant-card-head-title {
    font-weight: 700;
    font-size: 1.6rem;
    opacity: 1;
  }
}
</style>
