<template>
  <LoadingState v-if="isLoading" />
  <div v-else>
    <h1>Class by Subject | {{ userClass }}</h1>
    <!-- <img src="/resources/images/covers/Matematika/index.png" /> -->
    <ChartAllSubject :chartData="chartData" />
    <a-row v-if="subjects.length > 0" style="margin-top: 50px" :gutter="16">
      <a-col
        class="mb-4"
        :sm="12"
        :md="12"
        :xl="8"
        :key="subject.id"
        v-for="subject in subjects"
      >
        <CardSubject :subject="subject" />
      </a-col>
    </a-row>
    <EmptyState
      v-else
      heading="No Data"
      description="There is no subject on your class."
      class="my-5"
    />
  </div>
</template>
<script>
import CardSubject from '@/components/app/CardSubject'
import ChartAllSubject from '@/components/app/ChartAllSubject'
import EmptyState from '@/components/app/EmptyState'
import LoadingState from '@/components/app/LoadingState'
// const mapelWrapper = document.getElementById('mapel-list')
export default {
  data() {
    return {
      subjects: [],
      userClass: '',
      chartData: {
        labels: [],
        series: [],
        fullWidth: true,
      },
    }
  },
  components: {
    ChartAllSubject,
    CardSubject,
    EmptyState,
    LoadingState,
  },
  methods: {
    fetchDataSummaryAllSubject() {
      this.$store.dispatch('subject/FETCH_SUMMARY_ASSIGNMENT_ALL_SUBJECT', { idMurid: this.user.id })
        .then(res => {
          // console.log(res)
          const abjad = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q']
          const lengths = res.map(a => a.dataChart.length)
          const totalAssignment = res[lengths.indexOf(Math.max(...lengths))].totalAssignment
          for (let i = 1; i <= totalAssignment; i++) {
            this.chartData.labels.push(`#${i}`)
          }
          this.chartData.series = res.map((el, index) => {
            const series = []
            if (el.dataChart.length) {
              // console.log(el.dataChart)
              el.dataChart.forEach(elNilai => {
                series.push(elNilai.tugas_nilai)
              })
            }
            return {
              className: `ct-series-${abjad[index]}`,
              data: series,
              name: el.codeMapel,
            }
          })
          // console.log(this.chartData.series)
        })
    },
  },
  created() {
    this.$store.dispatch('subject/FETCH_SUBJECTS_STUDENT', { idMurid: this.user.id, idKelas: this.user.id_kelas })
      .then(() => {
        this.subjects = this.$store.state.subject.subjects.mapel
        this.userClass = `${this.$store.state.subject.subjects.kelas.tingkat}-${this.$store.state.subject.subjects.kelas.simbol}`
      })
    this.fetchDataSummaryAllSubject()
  },
  computed: {
    user() { return this.$store.state.user.user },
    isLoading() {
      return this.$store.state.isLoading
    },
  },
}
</script>
